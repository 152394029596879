.notificationCard {
  margin: 10px 50px;
}

.notificationbutton1 {
  display: flex;
  gap: 20px;
}
.notificationbutton2 {
  display: flex;
  gap: 10px;
}
.notificationItem {
  padding: 5px 20px;
  margin: 0px 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;

  h5 {
    margin-left: 13px;
  }
}
.notificationItem:hover {
  background-color: rgba(72, 72, 82, 0.253);
}

.notificationItem1 {
  h1 {
    margin-bottom: 0px;
  }
  p {
    border: solid 1px rgba(207, 201, 201, 0.938);
    width: fit-content;
    padding: 0px 3px;
    margin-bottom: 0px;
    font-size: 11px;
    border-radius: 3px;
    margin-left: 13px;
  }
}

.notificationicon {
  display: flex;
}
.approvalicon {
  margin-top: 4px;
}
.notificationPerson {
  display: flex;
  justify-content: space-between;
  p {
    opacity: 0.5;
  }
}
