.height600 {
    position: relative;
    height: 800px;
}

.examples--header {
    margin: 0 40px;
    text-align: center;
}

.dndOutsideSourceExample {
    @extend .examples--header;
    display: flex;
    > .inner {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-wrap: wrap;
        h4 {
            color: grey;
            width: 100%;
        }
        div[draggable] {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid gray;
            border-radius: 4px;
            width: 100px;
            margin: 10px;
            cursor: grab;
        }
        input[type="checkbox"] {
            margin-right: 5px;
        }
    }
}

.card {
    background-color: white;
    border: 0;
    padding: 24px;
    border-radius: 2px;
    margin-bottom: 20px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.isDraggable {
    cursor: grab !important;
}

.nonDraggable,
.nonResizable {
    background-color: lighten(#3bff4b, 40) !important;
    color: black !important;
}

.powderBlue {
    background-color: powderblue;
    color: black;
}

.darkGreen {
    background-color: darkgreen;
    color: white;
}

.slotDefault {
    background-color: "#FAF0E6";
    color: "black";
}

.tuesday {
    @extend .powderBlue;
}

#my_scheduler {
    @extend .powderBlue;
    padding: 0.5em;
    border: 1px solid blue;
}

.calendar-toolbar {
    margin-bottom: 10px;
    .buttons {
        position: absolute;
        right: 0px;
        h4 {
            padding-top: 7px;
        }
    }
}

.filtering .rbc-time-view,
.filtering .rbc-month-view {
    opacity: 0.1;
}

@media only screen and (max-width: 550px) {
    .rbc-calendar {
        .calendar-toolbar {
            .ant-row-center {
                justify-content: start;
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .main-content-wrapper {
        padding: 5px;
    }
    .height600 {
        position: relative;
        height: 350px;
    }
}

@media only screen and (min-width: 425px) {
    .calendar_events_mobile {
        display: none;
    }
    
}

.event-heading {
    position: fixed;
    top: 520px;
    text-align: center;
}

.calendar_events_mobile {
    padding-top: 0px;
    position: fixed;
    overflow-y: scroll;
    width: 85vw;
    max-height: 230px;
    top: 560px;
    ul {
        li {
            display: flex;
            flex-direction: row;
            gap: 20px;
            padding: 10px 0 10px 0;
            border-top: 2px solid #dedddc;
            // border-bottom: 1px solid black;
            &:last-child {
                border-bottom: 2px solid #dedddc; /* Added 'solid' and 'color' */
              
            }
            div {
                text-align: left;
                .events_date {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 600;
                    border: black;
                    background-color: bisque;
                    border-width: 1px;
                    border-radius: 50px;
                    margin-bottom: 10px;
                }
                p {
                    text-align: center;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}
