@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-Medium.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-Bold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../assets/fonts/AlegreyaSans-ExtraBold.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "PT Sans";
  src: url("../../assets/fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans";
  src: url("../../assets/fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
