.wrapper {
  // padding: 3% 15%;
  .backButton {
    margin-top: 2%;
    margin-left: 2%;
  }
  .login-form-wrapper {
    padding-top: 0;
  }
  .login-container {
    position: relative;
    padding-bottom: 10%;
    display: flex;

    flex-direction: column;
    justify-content: center;
    padding: 40px;

    .backButton-editProfile {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0.5%;
      margin-left: 1%;
    }

    .wrapper-form-col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .profile_pic-img {
        border: solid 1px blue;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      .profile_pic-img-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          border: solid 1px blue;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  
}