.events {
  &__filters {
    padding: 5px 0px 15px 0px;
  }
}

.events-columns-popover-h4 {
  color: grey;
}

.ant-table-cell .eventBookmark {
  // border: solid 1px black;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  // ....
  // margin-top: 10px;
}

.ant-table-cell {
  // border: solid 1px red;
  padding: 0;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td.ant-table-cell {
  padding: 4px 4px;

  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
}

.ant-table-cell h4 {
  margin-bottom: 0;
}

.events .ant-table-cell a {
  // border: solid 1px black;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 200px;
}

// -webkit-line-clamp: 2;
// -webkit-box-orient: vertical;
// overflow: hidden;
// text-overflow: ellipsis;
// display: -webkit-box;

.event_eventName {
  display: flex;
}
