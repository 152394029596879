.ant-skeleton-header {
  padding: 15px;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  width: 70px;
  height: 70px;
}

.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
  width: 128.766px;
}
