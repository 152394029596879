.my-day {
    margin: 0px !important;
    margin-bottom: 20px !important;

    &__title {
        padding-left: 5px;
    }

    .card {
        padding: 10px 20px !important;
    }

    .ant-scroll-number.ant-badge-count {
        background-color: #d9d9d9;
        color: black;
    }

    .worklist {
        justify-content: center;
        
        &__segment {
            display: flex;
            justify-content: center;
            .worklist--worklist__segment__to-do-icon {
                display: flex;
                padding: 5px;
                width: 80px;
                height: 35px;
                justify-content: space-between;
                align-items: center;
                h4 {
                    margin-top: 3px;
                }
            }
            .worklist--worklist__segment__Ongoing-icon {
                display: flex;
                padding: 5px;
                width: 100px;
                height: 35px;
                justify-content: space-between;
                align-items: center;
                h4 {
                    margin-top: 3px;
                }
            }
            .worklist--worklist__segment__Done-icon {
                display: flex;
                padding: 5px;
                width: 80px;
                height: 35px;
                justify-content: space-between;
                align-items: center;
                h4 {
                    margin-top: 3px;
                }
            }
        }

        .data {
            display: block;
            .data-panel-header {
                height: 20;
                width: 20;
                background-color: #dddddd;
                margin-top: 13;
                margin-right: 15;
            }
            .worklist-list-item {
                margin-left: 25;
               
                .worklist-list-item--avatar {
                    height: 20;
                    width: 20;
                    background-color: #dddddd;
                    margin-top: 13;
                }
            }
        }
    }
}

.day-info-modal {
    &__title {
        cursor: pointer;
    }
}

.redDuedate {
    color: red;
}
