.wrapper-register {
  height: 100vh;
  width: 100%;
  background-color: #b4bcc7;

  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "PT Sans", sans-serif !important;
}

.register-container {
  z-index: 10;
  background-color: #ffff;
  border-radius: 7px;
  width: 80%;

  .register-sideBar {
    margin: auto;
  }

  .backButton {
    display: inline;
    position: absolute;
    z-index: 100;
    margin-left: 1%;
    margin-top: 0.5%;
  }

  .ant-row {
    height: 100%;
  }

  .register-sidebar {
    height: 100%;
    width: 100%;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .register-form-wrapper {
    display: grid;
    place-items: center;
    height: 100%;
    padding: 30px;

    h2 {
      font-size: 30px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      margin-bottom: 16px;
      color: #767676;
    }

    .register-form {
      max-width: 450px;
      border-radius: 8px;
      width: 100%;
    }

    .ant-form {
      .ant-form-item {
        margin-bottom: 7px;
        .ant-input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
        &.form-submit-btn {
          text-align: center;
          margin: 30px 0 10px;
          .ant-btn-primary {
            min-width: 180px;
            padding-inline: 30px;
            font-size: 16px;
            height: auto;
            letter-spacing: 0.4px;
            font-weight: 600;
          }
        }
      }

      label {
        font-size: 16px;
      }

      .ant-form-item-label {
        text-transform: capitalize;
        font-size: 16px;

        letter-spacing: 0.3px;
        .ant-form-item-required {
          &:before {
            position: absolute;
            right: -13px;
            top: 6px;
          }
        }
      }

      .ant-form-item-control {
        font-size: 16px;
      }
      // ............................................................... privacy
    }
  }
}

@media all and (max-height: 800px) {
  .wrapper-register {
    height: 100%;
  }
}
