.ant-layout {
  .user-create-label {
    width: 80%;
    margin: auto;
    .button {
      display: flex;
      justify-content: center;
    }
  }
}
