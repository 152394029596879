.page-header {
  background: linear-gradient(
    90deg,
    rgba(244, 247, 252, 1) 0%,
    rgba(238, 236, 247, 1) 79%,
    rgba(211, 200, 240, 1) 100%
  ) !important;

  .avatar {
    border: 3px solid #fff;
    cursor: pointer;
  }
}

.ant-page-header-heading {
  top: 0;
  left: 240px;
  padding-left: 20px;
  position: fixed;
  z-index: 999;
  right: 0;
  padding-block: 10px;
  background: linear-gradient(
    90deg,
    rgba(244, 247, 252, 1) 0%,
    rgba(238, 236, 247, 1) 79%,
    rgba(211, 200, 240, 1) 100%
  ) !important;

  .ant-page-header-heading-title {
    font-weight: 700;
    font-size: 20px;
  }
}
.ant-page-header {
  padding: 0 !important;
}

.form-header {
  font-size: 22px;
}

.forgotPassword-page-header {
  svg {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.PageHeader-content {
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .ant-page-header-heading {
    position: initial;
    .ant-page-header-heading-left {
      margin: auto;
    }
  }
}
