.ant-layout {
  .steps-content {
    .ant-form {
      .form-parts {
        overflow-y: scroll;
        .form-contents-function {
          width: 35%;
          text-align: start;

          .label {
            font-size: 20px;
            font-weight: bolder;
          }
          .form-basic-details1 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .form-item-second-page1 {
              display: flex;
            }
            .icon {
              margin-top: 24px;
            }
            .form-item-second-page {
              width: 163px;
            }
          }
          .form-basic-details2 {
            display: finlinelex;
          }
        }
      }
    }
  }
}
