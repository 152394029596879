#app-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  margin-left: 240px;
  background-color: #f3f5fb !important;
}

.ant-layout-sider.ant-layout-sider-dark.sidebar {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.main-app-layout {
  background: linear-gradient(
    90deg,
    rgba(244, 247, 252, 1) 0%,
    rgba(238, 236, 247, 1) 79%,
    rgba(211, 200, 240, 1) 100%
  ) !important;

  .main-content {
    padding: 60px 20px 0;
  }
}

.sidebar {
  background-color: #f3f5fb !important;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 5px 0px;
    h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__menu {
    background: transparent !important;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      &.ant-menu-item-selected {
        background-color: transparent !important;
        &:after {
          left: 0;
          right: unset !important;
        }
      }
      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        &.active {
          font-weight: 700;
        }
        .icon {
          font-size: 20px;
          margin-right: 15px;
        }
        .addbtn {
          position: absolute;
          right: 20px;
          top: 8px;
          z-index: 99;
        }
      }
    }
  }

  .events {
    margin-top: 10px;
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      h4 {
        margin-top: 6px;
        font-size: 16px;
      }
    }

    &__title:hover .event-title {
      color: #1890ff;
    }

    &__title:hover .plus-icon {
      color: #1890ff;
    }

    &__list {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 370px);
      overflow-y: auto;

      a {
        color: #202124;
        align-items: center;
        padding: 5px 20px;
        display: flex;

        svg {
          margin-right: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .plus-icon {
    font-size: 16px;
    cursor: pointer;
  }

  .no-event {
    padding: 0px 20px;
    font-style: italic;
  }

  .see-all {
    a {
      color: #1890ff;
      font-weight: 600;
    }
  }
  &__footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.2px;

    .underline {
      text-decoration: underline;
    }
    .imark {
      font-size: 13px;
      span {
        color: #892c26;
      }
    }
  }
}

.sidebar__menu {
  border-right: 0;
  a.sidebar__menu__item__link {
    width: 100%;
    position: relative;
    .btn-vendor-create {
      position: absolute;
      right: -17px;
      top: 2px;

      svg {
        font-size: 16px;
      }
    }
  }
}

.active-menu {
  color: #1890ff;
  font-weight: 700;
  font-size: 16px;
}

.events__list::-webkit-scrollbar {
  width: 0.5vw;
}

.events__list::-webkit-scrollbar-thumb {
  background-color: #7775;
}

.events__list::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.events__list::-webkit-scrollbar-track {
  background-color: #5555;
}

// cursor: default;
// visibility: visible;
// z-index: 99999999 !important ;
// list-style-type: none;
// padding: 0.3em;

.ant-layout-sider.ant-layout-sider-dark.sidebar {
 .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 0px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background .3s ease;
}
}



@media only screen and (max-width: 992px) {
  #app-layout {
    margin-left: 0px;
  }
  .sidebar__footer {
    position: relative;
  }


}
