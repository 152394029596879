.wrapper-changePassword {
    height: 85vh;
    width: 100%;
    // background-color: #b4bcc7;

    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 15%;
    padding-right: 15%;

    font-family: "PT Sans", sans-serif !important;

    h1 {
        font-size: 22px;
        font-weight: 700;
        position: absolute;
        top: 15px;
        left: 15px;
        color: #fff;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }
}

.changePassword-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    // height: 100%;
    width: 100%;
   
    margin: auto;
    background-color: #ffff;
    border-radius: 7px;

    .ant-row {
        height: 100%;
    }

    .backButton {
        margin-top: 0.5%;
        margin-left: 1%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .changePassword-form-wrapper {
        width: 50%;
        min-width: 400px;
        display: grid;
        place-items: center;
        padding: 30px;
        position: relative;
        z-index: 5;

        h2 {
            font-size: 44px;
            font-weight: bold;
        }

        p {
            margin-bottom: 16px;
            color: #767676;
        }

        .changePassword-form {
            max-width: 450px;
            border-radius: 8px;
            width: 100%;
        }

        .ant-form {
            .ant-form-item {
                margin-top: 20px;
                .ant-input {
                    font-size: 14px;
                    &::placeholder {
                        font-size: 14px;
                    }
                }
                &.form-submit-btn {
                    text-align: center;
                    margin: 30px 0 10px;
                    .ant-btn-primary {
                        min-width: 180px;
                        padding-inline: 30px;
                        font-size: 16px;
                        height: auto;
                        letter-spacing: 0.4px;
                        font-weight: 600;
                    }
                }
            }

            label {
                font-size: 16px;
            }

            .ant-form-item-label {
                text-transform: capitalize;
                font-size: 16px;

                letter-spacing: 0.3px;
                .ant-form-item-required {
                    &:before {
                        position: absolute;
                        right: -13px;
                        top: 6px;
                    }
                }
            }

            .ant-form-item-control {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .changePassword-container {
        .changePassword-form-wrapper {
            width: 90% ;
            min-width: unset;

            h2{
              // font-size: 24px;
            }
        }

       
    }
}

@media only screen and (max-width: 425px) {
  .changePassword-container {
    .changePassword-form-wrapper {
     
        h2{
          font-size: 24px;
        }
    }

   
}
}
