.ant-divider.ant-divider-horizontal {
  margin: 0px;
}

.event-details__loadingWrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-top: 46.391px;
  .event-details__skeleton {
    display: flex;
    justify-content: space-around;
    width: 504.234px;
  }
}

.event-details {
  margin-bottom: 20px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .left-section {
      display: flex;
      align-items: center;
      .bookmark-icon {
        font-size: 23px;
        margin-right: 5px;
      }
      .name {
        // border: solid 1px black;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        max-width: 405px;
        word-wrap: break-word;

        //
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }

      .more {
        font-size: 25px;
        color: #b4bcc7;
        cursor: pointer;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__button {
      color: #202124 !important;
      font-weight: 400 !important;
      padding: 0px 0px;
      display: inherit !important;
      align-items: center !important;
      font-weight: 700;
      &:not(:last-child) {
        border-right: 1px solid #e8e8ea;
      }
      svg {
        color: #bdc4ce;
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
}

.print-container {
  display: none;
}
.function-card {
  .ant-collapse {
    background-color: #fff;
    border: 0;

    &-item {
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      overflow: hidden;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .btn-duedate {
        color: $red;
      }

      .ant-collapse-header {
        background: #f5f5f5;
        display: block !important;

        .function-card__collapse__header__buttons-row {
          span {
            color: #767676;
          }
        }
        .function-card__collapse__header__notes {
          .header-notes {
            color: #505050;
          }
        }
      }

      .ant-collapse-content {
        .task {
          .ant-collapse-item {
            border-radius: 10px;

            .ant-collapse-header {
              background: transparent;
            }

            .task__new-sub-task {
              .ant-form {
                .ant-form-item {
                  margin-bottom: 0;
                }

                .ant-btn-default {
                  background: $green;
                  color: #fff;
                  margin: 0 10px 0 30px;
                }

                .ant-btn-dashed {
                  border: 0;
                  background: #dbdbdb;
                }
              }
            }
          }
        }
      }
    }
  }
}

.function-card {
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          .task-label {
            .ant-col-16 {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.task__subtask {
  .subTaskHeader__col {
    .ant-btn {
      background: $green;
      color: #fff;
      margin: 0 10px 0 30px;
    }
  }
}

.task__collapse__header {
  .ant-btn-default {
    background: $green;
    color: #fff;
    margin: 0 10px 0 30px;
  }
}

.task__new-task {
  .ant-form {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-btn-default {
      background: $green;
      color: #fff;
      margin: 0 10px 0 30px;
    }

    .ant-btn-dashed {
      border: 0;
      background: #dbdbdb;
    }
  }
}

.ant-popover {
  .ant-popover-inner-content {
    .event-details__content {
      margin-bottom: 5px;
    }
  }
}

.events__filters {
  .ant-col {
    .ant-btn,
    .ant-picker {
      width: 100%;
    }
  }
}

.eventDate {
  margin-bottom: 10px;
  font-weight: 600;
  margin-left: 28px;
}

.eventDetail-noteTitle {
  display: flex;
  align-items: center;
}

.eventDetail-noteEdit {
  margin-right: 30px;
}

.eventDetailNoteSection .event-details__buttons__button {
  border-right: solid 1px #e8e8ea;
}

@media only screen and (max-width: 562px) {
  .ant-form {
    .ant-row {
      display: inline;

      .ant-col-10 {
        max-width: 100%;
      }
      .ant-col-5 {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  .event-details {
    .header {
      display: inline;
      .left-section {
        display: inline;
        .bookmark-icon {
          display: none;
        }
        .name {
          margin-left: 40%;
        }
        .more {
          display: none;
        }

        .event-details__buttons {
          justify-content: space-between;
          padding-top: 20px;
          .ant-btn {
            padding: 0px 0px;
          }
        }
      }
      .ant-btn.ant-btn-primary {
        margin: 20px 0px;
      }
    }
  }
  .event-details {
    .eventDate {
      text-align: end;
    }
    .function-card {
      .ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            .ant-collapse-header-text {
              .function-card__collapse__header {
                .ant-col-23 {
                  .ant-row {
                    overflow-x: scroll;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 448px) {
  .event-details {
    .header {
      display: inline;
      .left-section {
        .name {
          margin-left: 30%;
        }
      }
    }
  }
}

@media only screen and (max-width: 555px) {
  .event-details {
    .eventDate {
      text-align: end;
    }
    .function-card {
      .ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            .ant-collapse-header-text {
              .function-card__collapse__header {
                .ant-col-23 {
                  .ant-row {
                    display: inline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .event-details {
    .eventDate {
      display: none;
    }
  }

  .function-card {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          .ant-collapse-content-box {
            .task {
              .task__collapse {
                .ant-collapse-item {
                  .task__new-sub-task {
                    .ant-form {
                      .ant-row {
                        .subcolumn {
                          display: flex;
                          justify-content: center;
                          margin-top: 10px;
                        }
                      }
                    }
                  }

                  .ant-collapse-header {
                    .ant-collapse-header-text {
                      .ant-row {
                        flex-direction: column;
                      }
                    }
                  }

                  .ant-collapse-content {
                    .ant-collapse-content-box {
                      .ant-row {
                        flex-direction: column;
                      }
                    }
                  }
                }
              }
              .task__new-task {
                .ant-form {
                  .ant-row {
                    .column {
                      display: flex;
                      justify-content: center;
                      flex-wrap: wrap;
                      margin-top: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-form {
    .ant-row {
      .ant-col-12 {
        max-width: 100%;
      }
    }
  }

  .task__new-task {
    .ant-form {
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-btn-default {
        background: $green;
        color: #fff;
        margin: 0 10px 0 30px;
      }

      .ant-btn-dashed {
        border: 0;
        background: #dbdbdb;
      }
    }
  }
}
