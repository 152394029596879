.steps-content {
  min-height: 200px;
  margin-top: 16px;

  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin: auto;
  padding: 10px 0px;
  text-align: center;
}
[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.65);
  padding-top: 80px;
}

.ant-layout {
  .ant-steps {
    margin: 5px 0px;
    padding: 0 80px;
  }
  .steps-content {
    .ant-form {
      .form-parts {
        width: full;
        margin: auto;
        background-color: white;
        display: flex;
        justify-content: center;
        column-gap: 20%;
        height: 82vh;
        align-items: center;

        .form-contents {
          width: 30%;
          text-align: start;

          .label {
            font-size: 20px;
            font-weight: bolder;
          }
          .form-basic-details1 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
          .form-basic-details2 {
            display: finlinelex;
          }
        }
        .form-image img {
          max-width: auto;
          max-height: 70vh;
        }
      }

      .date-picker {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
      .function-range {
        display: flex;
        justify-content: space-between;
        .time {
          width: 50%;
        }
      }
    }
  }
}

.steps-content {
  .functionContent {
    .functionform {
      width: 100%;
      .ant-form-item {
        .ant-row {
          .ant-btn {
            width: 30%;
          }
        }
      }
      .topmain {
        margin-top: 20px;
        .main {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .main-content {
            display: contents;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .steps-content {
    .functionContent {
      .functionform {
        width: 100%;
        .ant-form-item {
          .ant-row {
            .ant-btn {
              width: 30%;
            }
          }
        }
        .topmain {
          margin-top: 20px;
          .main {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .main-content {
              display: inline;
            }
          }
        }
      }
    }
  }
}
