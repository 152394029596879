* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Add these styles to your CSS or style tag */
.file-import-upload {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4pxrgb (122, 51, 51);
}

.import-button {
  background-color: #4c75af;
  width: 120px;
  color: white;
  border: none;
  padding: 5px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
