@import "./base/";

@import "./layout/layout";

@import "./pages/pages";

@import "./components/component";

.rbc-month-view {
    .rbc-month-row {
        .rbc-off-range {
            background-color: lightgray; /* Change this to your desired color */
        }
    }
}
