.assign-vendor-modal {
  .vendor-select {
    width: 100%;
  }
}

.note_modal {
  .parsed-html {
    display: block;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  ul,
  ol {
    padding: 0px 15px !important;
  }
}

.parsed-html {
  img {
    height: auto;
    max-width: 100%;
  }


}
@media only screen and (max-width: 576px) {
  .ant-modal-body {
    .ant-form {
      .ant-row,
      .ant-row:after,
      .ant-row:before {
        display: inline;
      }
      .ant-row {
        .ant-col-14 {
          display: block;
          max-width: 100%;
        }
        .ant-col-10 {
          max-width: 100%;
        }
      }
    }
  }
}
