.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .forgot-link {
    display: flex;
    font-size: 1.4rem;
    align-items: flex-end;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100vh;
  width: 100%;

  position: relative;

  font-family: "PT Sans", sans-serif !important;

  h1 {
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    top: 15px;
    left: 15px;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
}

.login-container1 {
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: #ffff;
  border-radius: 7px;

  .ant-row {
    height: 100%;
  }

  .login-sidebar {
    background-image: url("../../img/loginbackground.jpg");
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-logo {
      background-color: white;
      padding: 20px 20px;
      text-align: center;
      align-items: center;
      border-radius: 5px;
      .login-logo-inner {
        border: 2px solid #c7a13f;
        padding: 30px 40px;
        img {
          height: 20vh;
          width: fit-content;
        }
        h6 {
          margin-top: 10px;
          color: black;
          font-family: Jim Nightshade;
          font-size: 15px;
        }
        button {
          padding: 5px 10px;
          color: white;
          background-color: #c7a13f;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }

  .login-form-wrapper {
    display: flex;
    align-items: center;

    height: 100%;
    position: relative;
    z-index: 5;

    h2 {
      font-size: 35px;
      font-weight: bold;
    }

    p {
      margin-bottom: 16px;
      color: #767676;
    }

    .login-form {
      width: 100%;
      text-align: center;
      padding: 10px 50px;
      img {
        max-width: 50%;
        height: auto;
      
      }
    }

    .ant-form {
      .ant-form-item {
        margin-top: 20px;
        .ant-input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
        &.form-submit-btn {
          text-align: center;
          margin: 20px 60px;

          .ant-btn-primary {
            min-width: 180px;
            padding-inline: 30px;
            font-size: 16px;
            height: auto;
            letter-spacing: 0.4px;
            font-weight: 600;
            background-color: #ff4f4f;
            border: none;
          }
        }
      }

      label {
        font-size: 16px;
      }

      .ant-form-item-label {
        text-transform: capitalize;
        font-size: 16px;

        letter-spacing: 0.3px;
        .ant-form-item-required {
          &:before {
            position: absolute;
            right: -13px;
            top: 6px;
          }
        }
      }

      .ant-form-item-control {
        font-size: 16px;
      }
    }
    // ............................................................... privacy

    .login-link {
      text-align: center;
      font-size: 14px;

      a {
        margin-left: 7px;
        font-weight: 700;
      }
    }
  }
}

.powered-by {
  font-family: "Alegreya Sans", sans-serif !important;
  position: absolute;
  left: 5%;
  bottom: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.2px;
  z-index: 10;

  .underline {
    text-decoration: underline;
  }

  .imark {
    font-size: 13px;
    span {
      color: #892c26;
    }
  }
}

@media (min-width: 1440px) {
  .login-form-wrapper {
    h2 {
      font-size: 2px;
      font-weight: bold;
    }

    p {
      margin-bottom: 16px;
      color: #767676;
    }
  }
}

@media only screen and (max-width: 700px) {
  .wrapper {
    height: unset;
    padding-top: 30px;
    // height: 100vh;
    .login-form-wrapper {
      height: unset;
      h2 {
        font-size: 30px;
      }
    }
  }
}
//responsive design
@media only screen and (max-width: 1024px) {
  .wrapper {
    // height: 100vh;
    // background-color: #3a62dc;
    display: flex;
    align-items: center;
  }
  .login-container1 {
    display: inline;
    height: fit-content;
    .login-form-wrapper p {
      text-align: center;
    }
    .forgot-password {
      text-align: center;
    }

    .ant-row {
      .ant-col-8 {
        max-width: 100%;
      }
      display: inline;
      .ant-col-13 {
        display: initial;
        .login-form-wrapper {
          display: initial;

          .login-form {
            max-width: 100%;
            margin-left: 0;
            .ant-form .ant-form-item {
              margin-bottom: 10px;
              .ant-form-item-row .ant-form-item-label .ant-form-item-required {
                text-align: start;
              }
              .ant-btn-primary {
                width: fit-content;
              }
            }
          }
        }
      }
      .ant-col-11 {
        display: none;
      }
    }

    .login-form-wrapper h2 {
      font-size: 44px;
      font-weight: bold;
      text-align: center;
    }

    .login-sidebar {
      display: none;
    }
  }
}
