.vendor-details {
  .vendor-details-header {
    .card {
      padding: 18px !important;
    }
    .note-section {
      word-break: break-word;
      display: flex;

      .image {
        img {
          max-width: 700px;
        }
      }
      .note {
        width: 45px;
      }
    }
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 9px 0;
  }

  .ant-descriptions-item-container .ant-descriptions-item-label {
    font-weight: 600;
  }

  // .ant-descriptions-item-content{
  //   font-weight: 600;
  // }

  .divider-title {
    font-weight: 800;
  }
  .tasks-count-value {
    font-size: 22px;
    margin-top: -8px;
  }
}

.print-button {
  border-left: solid 1px #e8e8ea;
  padding: 0;
  color: #202124 !important;
  font-weight: 500;
  letter-spacing: 0.2px;

  svg {
    color: #202124;
    font-size: 16px;
    margin-right: 5px;
  }
}

.ant-descriptions-item-container .ant-descriptions-item-label {
  font-weight: bold;
}

.vendorProfile {
  .ant-descriptions-header {
    margin-bottom: 5px;
  }
  .ant-descriptions-item {
    padding-bottom: 3px !important;
  }
}

.completed-icon {
  display: flex;
  .green-check {
    height: 20px;
    width: 20px;
    margin-left: 20px;
  }
}

.ant-descriptions-item {
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
}

.vendor-table .ant-table-cell a {
  // border: solid black 1px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 300px;
}

.divider-title {
  // border: solid 1px black;
  max-width: 400px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  word-wrap: break-word;
}

@media only screen and (max-width: 562px) {
  .ant-form {
    .ant-row {
      .ant-col-15 {
        max-width: 100%;
      }
      .ant-col-7 {
        max-width: 100%;
      }
      .ant-col-2 {
        max-width:20%;
    }
    }
  }
}
